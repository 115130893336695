/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= vite_javascript_tag 'entrypoints/application'
// layout file, like app/views/layouts/application.html.erb
window.jQuery = window.$ = $
import './remote_modal';
import './users_sidemenu';
// import './footer_fixed';
// import './dropdown';
// import './top-search-form';
// import './accordion_hamburger';

import "regenerator-runtime/runtime";
import "core-js/stable";
// import "babel-polyfill"
// import DisplayCsvFileName from './display_csv_file_name';
// import Sorter from './sorter';
import './vue_main';
import Rails from "@rails/ujs";
Rails.start();

document.addEventListener('DOMContentLoaded', () => {
  // new DisplayCsvFileName();
  // new Sorter();

  $('.js-back-to-top').on('click', () => {
    $('body, html').animate({ scrollTop: 0 }, 500);
  });
});

$(window).scroll(() => {
  if ($(window).scrollTop() >= 800) {
    // 求人詳細ページでフローティング応募ボタンを設置
    if(location.pathname.match(/\/jobs|licenses|categories|prefectures/)) {
      $('.c-floating-menu__js').fadeIn(100);
    } else {
      $('.js-back-to-top').fadeIn(100);
    }
  } else {
    if(location.pathname.match(/\/jobs|licenses|categories|prefectures/)){
      $('.c-floating-menu__js').fadeOut(100);
    } else {
      $('.js-back-to-top').fadeOut(100);
    }
  }
});


<div class="c-modal p-location-modal" v-show="isActive" v-on="handlers">
  <div v-if="!isMobile" class="c-modal__header">
    <div class="c-modal__header__close" @click="closeModal"></div><span>エリアを選択する</span>
  </div>
  <area-selector :areas="areas" :selected-area="currentArea" @area-clicked="areaClicked"></area-selector>
  <div class="p-location-modal__prefecture-selector">
    <prefecture-selector :prefectures="prefectures" :selected-prefecture="currentPrefecture" @prefecture-clicked="prefectureClicked"></prefecture-selector>
  </div>
  <div v-if="isMobile" class="c-modal__header__close -float" @click="closeModal"></div>
  <div class="p-location-modal__cities-panel">
    <cities-panel :is-loading="isLoading" ref="cp" :cities="cities" :selected-prefecture="currentPrefecture"></cities-panel>
    <input v-for="city in cities_without_prefecture" :key="city" type="hidden" name="db_companies_search[city_names][]" :value="city">
  </div>
  <div class="p-location-modal__section">
    <div class="p-location-modal__conditions__header">市区町村の条件：</div>
    <div class="p-location-modal__conditions__panel">
      <span v-for="condition in conditionsArray" :key="condition" class="p-location-modal__conditions__tags" @click="deleteCondition(condition)">{{ condition }}</span>
    </div>
  </div>
  <div class="p-location-modal__section">
    <div class="p-prefecture-modal__action text-center">
      <div v-if="!isMobile" class="c-button -large -blue p-prefecture-modal__action__button" @click="closeModal">選択</div>
      <div v-if="isMobile" class="p-location-modal__close-drawer" @click="closeModal"></div>
    </div>
  </div>
</div>

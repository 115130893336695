
<div>
  <!-- <div v-show=isLoading class='c-loading__spinner centered'></div> -->
  <table v-show=!isLoading>
    <tr><th><label class="c-checkbox">
      <input type=checkbox :value=selectedPrefecture v-model=prefecture_names>
      <span class="c-checkbox__text">{{selectedPrefecture}}</span>
    </label></th></tr>
    <tr>
    <td v-for="city in cities" :key=city>
      <label class="c-checkbox">
        <input type=checkbox :value=city  v-model=city_names>
        <span class="c-checkbox__text">{{city}}</span>
      </label>
    </td>
    </tr>
  </table>
</div>

<template>
  <div class="c-modal p-location-modal" v-show="isActive" v-on="handlers">
    <div v-if="!isMobile" class="c-modal__header">
      <div class="c-modal__header__close" @click="closeModal"></div><span>エリアを選択する</span>
    </div>
    <area-selector :areas="areas" :selected-area="currentArea" @area-clicked="areaClicked"></area-selector>
    <div class="p-location-modal__prefecture-selector">
      <prefecture-selector :prefectures="prefectures" :selected-prefecture="currentPrefecture" @prefecture-clicked="prefectureClicked"></prefecture-selector>
    </div>
    <div v-if="isMobile" class="c-modal__header__close -float" @click="closeModal"></div>
    <div class="p-location-modal__cities-panel">
      <cities-panel :is-loading="isLoading" ref="cp" :cities="cities" :selected-prefecture="currentPrefecture"></cities-panel>
      <input v-for="city in cities_without_prefecture" :key="city" type="hidden" name="db_companies_search[city_names][]" :value="city">
    </div>
    <div class="p-location-modal__section">
      <div class="p-location-modal__conditions__header">市区町村の条件：</div>
      <div class="p-location-modal__conditions__panel">
        <span v-for="condition in conditionsArray" :key="condition" class="p-location-modal__conditions__tags" @click="deleteCondition(condition)">{{ condition }}</span>
      </div>
    </div>
    <div class="p-location-modal__section">
      <div class="p-prefecture-modal__action text-center">
        <div v-if="!isMobile" class="c-button -large -blue p-prefecture-modal__action__button" @click="closeModal">選択</div>
        <div v-if="isMobile" class="p-location-modal__close-drawer" @click="closeModal"></div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import AreaSelector from './area_selector.vue'
import PrefectureSelector from './prefecture_selector.vue'
import CitiesPanel from './cities_panel.vue'
import {state, mutations} from '../../stores/db_companies_search/store'

export default {
  props: {
    isMobile: Boolean,
  },
  data() {
    const vm = this
    return {
      handlers:{
        'area-clicked': vm.areaClicked,
        'prefecture-clicked': vm.prefectureClicked,
      },
      areaPrefectures: {},
      prefCities: {},
      currentArea: '',
      currentPrefecture: '',
      name: 'location-modal',
      // to-do: fix state reactivity
      activeEl: state.activeComponent,
      isLoading: false,
    }
  },
  created(){
    axios
      .get('/api/area_prefectures')
      .then(response => {
        this.areaPrefectures = response.data
      })
      .catch(e => {console.log(e)})
      .finally(() =>{
        this.currentArea = this.areas[0]
        this.prefectureClicked(this.prefectures[0])
      })
    if (state.dbCompaniesSearch.prefecture_names.length <= 0){return}

    let promise = ''
    state.dbCompaniesSearch.prefecture_names.forEach((pn) => {
      promise = this.fetchCities(pn)
    })
    promise.then( () => {
      mutations.setCities(state, this.all_cities.concat(state.dbCompaniesSearch.city_names))
    })
  },
  computed:{
    cities(){
      if (this.prefCities[this.currentPrefecture] == undefined){return []}

      return(this.prefCities[this.currentPrefecture])
    },
    areas(){
      return(Object.keys(this.areaPrefectures))
    },
    prefectures(){
      return(this.areaPrefectures[this.currentArea])
    },
    all_cities(){
      return state.dbCompaniesSearch.prefecture_names.map((key) => {return this.prefCities[key]}).flat()
    },
    cities_without_prefecture(){
      return state.dbCompaniesSearch.city_names.filter(x => this.all_cities.indexOf(x) == -1)
    },
    conditionsArray(){
      let locations_arr =  state.dbCompaniesSearch.prefecture_names.concat(this.cities_without_prefecture)
      mutations.setLocationsArray(state, locations_arr)
      return state.locationsArray
    },
    activeComponent:{
      get: function() {
        return state.activeComponent
      },
      set: function(value){
        mutations.setactiveComponent(state, value)
        // to-do fix state reactivity (state.activeComponent is not reactive for some unknown reason)
        // current work around
        this.activeEl = state.activeComponent
      }
    },
    isActive(){
      return this.activeEl == this.name
    }
  },
  components:{
    AreaSelector,
    PrefectureSelector,
    CitiesPanel
  },
  methods:{
    closeModal(){
      this.activeComponent = ''
    },
    openModal(){
      this.activeComponent = this.name
    },
    areaClicked(area){
      this.currentArea = area
      this.prefectureClicked(this.prefectures[0])
    },
    resetPrefecture(){
      this.currentPrefecture = ''
      this.cities = []
    },
    prefectureClicked(prefecture){
      this.currentPrefecture = prefecture
      this.fetchCities(prefecture)
    },
    deleteCondition(e){
      let i = state.dbCompaniesSearch.prefecture_names.indexOf(e)
      let j = state.dbCompaniesSearch.city_names.indexOf(e)
      if ( i > -1){
        let b = state.dbCompaniesSearch.city_names.filter(x => !this.prefCities[e].includes(x))
        mutations.setCities(state, b)
        state.dbCompaniesSearch.prefecture_names.splice(i)
      }
      else if (j > -1){
        state.dbCompaniesSearch.city_names.splice(j,1)
      }
    },
    async fetchCities(prefecture) {
      if (this.prefCities[this.currentPrefecture] != undefined) { return }

      this.isLoading = true
      try {
        // APIリクエスト
        const response = await axios.get('/api/cities?prefecture=' + prefecture)
        this.$set(this.prefCities, prefecture, response.data)
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

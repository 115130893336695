<template>
<div :class=classes.selector>
  <button v-if=isMobile class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" @click=titleClicked()>{{selectedPrefecture}}<span class="caret"></span></button>
  <ul :class=classes.ul>
    <li class="u-w100" v-for="prefecture in prefectures" :key="prefecture" @click=prefectureClicked(prefecture) :class="{active: prefecture==selectedPrefecture}"> {{prefecture}} </li>
  </ul>
</div>
</template>

<script>
export default {
  props: {
    prefectures: Array,
    selectedPrefecture: String
  },
  data() {
    return {
      isMobile: false,
      classes: {
        selector: 'c-tab',
        ul: ''
     }
    }
  },
  mounted() {
    this.isMobile = this.$parent.isMobile
    if (this.isMobile){
      this.classes.selector = 'dropdown'
      this.classes.ul = 'dropdown-menu'
    }
  },
  methods: {
    prefectureClicked(e){
      this.$emit('prefecture-clicked', e)
      if (this.isMobile){
        this.classes.ul = 'dropdown-menu'
      }
    },
    titleClicked() {
      if (this.classes.ul === 'dropdown-menu show') {
        this.classes.ul = 'dropdown-menu'
      } else {
        this.classes.ul = 'dropdown-menu show'
      }
    }
  }
}
</script>

<style scoped>
.show {
  display: block;
}
</style>
